@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  h1 {
    @apply font-semibold text-5xl;
  }
  h2 {
    @apply font-semibold text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  textarea,
  select,
  input[type]:not([type="radio"]):not([type="checkbox"]):not([type="hidden"]) {
    @apply rounded border border-gray-200 p-3;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .static-page h2, .static-page p {
    @apply mb-3
  }

  .static-page h1 {
    @apply mb-10
  }

  .static-page li {
    @apply list-disc mb-3 ml-5
  }
}

.autogrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 1rem;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
